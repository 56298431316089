var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"popover-menu-list popover-group-menu-member-list\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"members"),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":25,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"popover-group-menu-member\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":22,"column":35}}})) != null ? stack1 : "")
    + "                            <span class=\"popover-group-menu-member-name\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"full_name") || container.strict(depth0, "full_name", {"start":{"line":23,"column":75},"end":{"line":23,"column":84}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":23,"column":73},"end":{"line":23,"column":86}}}) : helper)))
    + "</span>\n                        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"user_circle "
    + alias4((((helper = lookupProperty(helpers,"user_circle_class") || alias1(depth0, "user_circle_class", {"start":{"line":21,"column":59},"end":{"line":21,"column":76}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":21,"column":57},"end":{"line":21,"column":78}}}) : helper)))
    + " popover_user_presence hidden-for-spectators\" data-tippy-content=\""
    + alias4((((helper = lookupProperty(helpers,"user_last_seen_time_status") || alias1(depth0, "user_last_seen_time_status", {"start":{"line":21,"column":146},"end":{"line":21,"column":172}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":21,"column":144},"end":{"line":21,"column":174}}}) : helper)))
    + "\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"popover-group-menu-placeholder\"><i>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This group has no members.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":64},"end":{"line":28,"column":98}}}))
    + "</i></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a href=\""
    + alias2((((helper = lookupProperty(helpers,"group_edit_url") || container.strict(depth0, "group_edit_url", {"start":{"line":34,"column":27},"end":{"line":34,"column":41}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"group_edit_url","hash":{},"data":data,"loc":{"start":{"line":34,"column":25},"end":{"line":34,"column":43}}}) : helper)))
    + "\" role=\"menuitem\" class=\"navigate-link-on-enter popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":53},"end":{"line":36,"column":76}}}))
    + "</span>\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu user-group-info-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <div class=\"popover-group-menu-info\">\n                <div class=\"popover-group-menu-name-container\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-triple-users\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-group-menu-name\">"
    + alias4((((helper = lookupProperty(helpers,"group_name") || alias1(depth0, "group_name", {"start":{"line":7,"column":60},"end":{"line":7,"column":70}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":72}}}) : helper)))
    + "</span>\n                </div>\n                <div class=\"popover-group-menu-description\">"
    + alias4((((helper = lookupProperty(helpers,"group_description") || alias1(depth0, "group_description", {"start":{"line":9,"column":62},"end":{"line":9,"column":79}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_description","hash":{},"data":data,"loc":{"start":{"line":9,"column":60},"end":{"line":9,"column":81}}}) : helper)))
    + "</div>\n            </div>\n        </li>\n        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(lookupProperty(depth0,"members"),"length"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(helpers,"or").call(alias2,lookupProperty(depth0,"is_guest"),lookupProperty(depth0,"is_system_group"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":31,"column":18},"end":{"line":31,"column":47}}}),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});