var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"create_user_group_button create_user_group_plus_button\">\n                            <span class=\"create_button_plus_sign\">+</span>\n                        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a href=\"#groups/new\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Create a user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":54},"end":{"line":40,"column":81}}}))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"create_user_group_button animated-purple-button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Create user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":98},"end":{"line":54,"column":124}}}))
    + "</button>\n                    <span class=\"settings-empty-option-text\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":59,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            First time? Read our <z-link>guidelines</z-link> for creating user groups.\n                            \n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/user-groups\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"groups_overlay\" class=\"overlay new-style\" data-overlay=\"group_subscriptions\">\n    <div class=\"flex overlay-content\">\n        <div class=\"user-groups-container overlay-container\">\n            <div class=\"user-groups-header\">\n                <div class=\"fa fa-chevron-left\"></div>\n                <span class=\"user-groups-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":68}}}))
    + "</span>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"left\">\n                <div class=\"list-toggler-container\">\n                    <div id=\"add_new_user_group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                        <div class=\"float-clear\"></div>\n                    </div>\n                </div>\n                <div class=\"input-append group_name_search_section\" id=\"group_filter\">\n                    <input type=\"text\" name=\"group_name\" id=\"search_group_name\" class=\"filter_text_input\" autocomplete=\"off\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":57}}}))
    + "\" value=\"\"/>\n                    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_group_name\">\n                        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n                <div class=\"no-groups-to-show\">\n                    <div class=\"your_groups_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are not a member of any user groups.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":76}}}))
    + "\n                            <a href=\"#groups/all\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View all user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":50},"end":{"line":33,"column":78}}}))
    + "</a>\n                        </span>\n                    </div>\n                    <div class=\"all_groups_tab_empty_text\">\n                        <span class=\"settings-empty-option-text\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no user groups you can view in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":38,"column":95}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":41,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </div>\n                </div>\n                <div class=\"user-groups-list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                </div>\n            </div>\n            <div class=\"right\">\n                <div class=\"display-type\">\n                    <div id=\"user_group_settings_title\" class=\"user-group-info-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":86},"end":{"line":50,"column":114}}}))
    + "</div>\n                </div>\n                <div class=\"nothing-selected\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":61,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div id=\"user_group_settings\" class=\"settings\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./user_group_creation_form.hbs"),depth0,{"name":"user_group_creation_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":58,"column":28},"end":{"line":58,"column":154}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});