var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Leave group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":40}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Join group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":39}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group_settings_header\" data-group-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "id", {"start":{"line":1,"column":52},"end":{"line":1,"column":60}} ), depth0))
    + "\">\n    <div class=\"tab-container\"></div>\n    <div class=\"button-group\">\n        <div class=\"join_leave_button_wrapper inline-block\">\n            <button class=\"button small rounded join_leave_button\" type=\"button\" name=\"button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_member"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n        </div>\n        <button class=\"button small rounded btn-danger deactivate tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Delete group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":115},"end":{"line":13,"column":135}}}))
    + "\" type=\"button\" name=\"delete_button\"> <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></button>\n    </div>\n</div>\n<div class=\"user_group_settings_wrapper\" data-group-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "id", {"start":{"line":16,"column":58},"end":{"line":16,"column":66}} ), depth0))
    + "\">\n    <div class=\"inner-box\">\n\n        <div class=\"group_general_settings group_setting_section\" data-group-section=\"general\">\n            <div class=\"group-header\">\n                <div class=\"group-name-wrapper\">\n                    <span class=\"group-name\" title=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "name", {"start":{"line":22,"column":54},"end":{"line":22,"column":64}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "name", {"start":{"line":22,"column":70},"end":{"line":22,"column":80}} ), depth0))
    + "</span>\n                </div>\n                <div class=\"group_change_property_info alert-notification\"></div>\n                <div class=\"button-group\">\n                    <button id=\"open_group_info_modal\" class=\"button rounded small btn-warning tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Change group info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":144},"end":{"line":26,"column":170}}}))
    + "\">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"group-description-wrapper\">\n                <span class=\"group-description\">\n                    "
    + alias3(alias2(alias1(lookupProperty(depth0,"group"), "description", {"start":{"line":33,"column":22},"end":{"line":33,"column":39}} ), depth0))
    + "\n                </span>\n            </div>\n\n            <div class=\"group-permissions settings-subsection-parent\" id=\"group_permission_settings\">\n                <div class=\"subsection-header\">\n                    <h3 class=\"user_group_setting_subsection_title\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":68},"end":{"line":39,"column":94}}}))
    + "\n                    </h3>\n                    "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),depth0,{"name":"../settings/settings_save_discard_widget","hash":{"section_name":"group-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                "
    + ((stack1 = container.invokePartial(require("./group_permissions.hbs"),depth0,{"name":"group_permissions","hash":{"can_manage_group_widget_name":"can_manage_group","can_mention_group_widget_name":"can_mention_group"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"group_member_settings group_setting_section\" data-group-section=\"members\">\n            <div class=\"edit_members_for_user_group\">\n                "
    + ((stack1 = container.invokePartial(require("./user_group_members.hbs"),depth0,{"name":"user_group_members","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});