var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"right-sidebar\" id=\"right-sidebar\" role=\"navigation\">\n    <div class=\"right-sidebar-items\">\n        <div id=\"user-list\">\n            <div id=\"userlist-header\">\n                <h4 class='right-sidebar-title' id='userlist-title'>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"USERS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":34}}}))
    + "\n                </h4>\n                <i id=\"user_filter_icon\" class=\"fa fa-search\"></i>\n            </div>\n            <div class=\"notdisplayed\" id=\"user_search_section\">\n                <input class=\"user-list-filter home-page-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":126},"end":{"line":11,"column":148}}}))
    + "\" />\n                <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_people_button\">\n                    <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n            <div id=\"buddy_list_wrapper\" class=\"scrolling_list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                <div id=\"buddy-list-users-matching-view-container\">\n                    <div class=\"buddy-list-subsection-header\"></div>\n                    <ul id=\"buddy-list-users-matching-view\" class=\"buddy-list-section filters\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"None.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":122},"end":{"line":19,"column":136}}}))
    + "\"></ul>\n                </div>\n                <div id=\"buddy-list-other-users-container\">\n                    <div class=\"buddy-list-subsection-header\"></div>\n                    <ul id=\"buddy-list-other-users\" class=\"buddy-list-section filters\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"None.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":114},"end":{"line":23,"column":128}}}))
    + "\"></ul>\n                </div>\n                <div id=\"buddy_list_wrapper_padding\"></div>\n            </div>\n        </div>\n        <div class=\"right-sidebar-shortcuts\">\n            <a class=\"invite-user-link\" role=\"button\"><i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":104},"end":{"line":29,"column":141}}}))
    + "</a>\n        </div>\n    </div>\n</div>\n";
},"useData":true});