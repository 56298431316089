var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./message_reaction.hbs"),depth0,{"name":"message_reaction","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"message_reactions"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + "<div class=\"reaction_button\" role=\"button\" aria-haspopup=\"true\" data-tooltip-template-id=\"add-emoji-tooltip-template\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":130},"end":{"line":4,"column":157}}}))
    + " (:)\">\n    <div class=\"emoji-message-control-button-container\">\n        <i class=\"zulip-icon zulip-icon-smile\" tabindex=\"0\"></i>\n        <div class=\"message_reaction_count\">+</div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});